import React from 'react'
import { T } from '../../typo'
import { s, globals } from '../../style'

const Partners = ({ data, theme }) => {
  const { slug_section, title } = data
  const partners = data.partners.concat(data.partners)
  return (
    <section id={slug_section} css={{ contentVisibility: 'auto' }}>
      <T d={14} m={12} o={0.3} upper bold spacious center extraCss={sStyle}>
        {title}
      </T>
      <div css={sSlider(theme)}>
        <div css={sSliderTrack(partners?.length)}>
          {partners.map(({ image }, id) => (
            <div className="slide" key={id}>
              <img src={image.publicURL} alt={image.name} />
            </div>
          ))}
          {partners.map(({ image }, id) => (
            <div className="slide" key={id}>
              <img src={image.publicURL} alt={image.name} />
            </div>
          ))}
          {partners.map(({ image }, id) => (
            <div className="slide" key={id}>
              <img src={image.publicURL} alt={image.name} />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

const sStyle = {
  [s.sm_down]: {
    marginTop: globals.spacing.between.mobile,
    paddingTop: globals.menu.height.mobile + globals.spacing.outside.mobile,
  },
  [s.md]: {
    marginTop: globals.spacing.between.desktop,
    paddingTop: globals.menu.height.desktop + globals.spacing.outside.desktop,
  },
  paddingBottom: '1rem',
}

const imageWidth = 250
const imageHeight = 160
const imageMargin = 140

const sSlider = (theme) => ({
  // backgroundColor: colors[theme].container,
  // boxShadow: globals.shadows.buttons,
  height: imageHeight,
  margin: 'auto',
  overflow: 'hidden',
  position: 'relative',
  width: '100vw',
  marginTop: '2rem',
  marginBottom: '3rem',

  '&::before, &::after': {
    content: '""',
    height: imageHeight,
    position: 'absolute',
    zIndex: 2,
    width: imageHeight,
  },

  '&::after': {
    right: 0,
    top: 0,
    transform: 'rotateZ(180deg)',
  },

  '&::before': {
    left: 0,
    top: 0,
  },

  '.slide': {
    filter: `grayscale(1) invert(${theme === 'light' ? 0 : 1}) brightness(${
      theme === 'light' ? 1 : 1
    })`,
    opacity: theme === 'light' ? 0.16 : 0.16,
  },
})

const sSliderTrack = (number) => ({
  // width: (imageWidth + imageMargin) * number,
  display: 'flex',
  width: 'max-content',
  animation: `scroll ${2 * number}s linear infinite`,
  '@keyframes scroll': {
    '0%': { transform: 'translateX(0)', opacity: 1 },
    '100%': {
      transform: `translateX(-${(imageWidth + imageMargin) * number}px)`,
      opacity: 1,
    },
  },

  '.slide': {
    flexShrink: 0,
    height: imageHeight,
    width: imageWidth,
    maxWidth: imageWidth,
    maxHeight: imageHeight,
    margin: `0 ${imageMargin / 2}px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    img: {
      height: imageHeight,
      width: imageWidth,
      maxWidth: imageWidth,
      maxHeight: imageHeight,
      objectFit: 'contain',
    },
  },
})

export default Partners
